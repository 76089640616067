.menuBar {
  padding: 0 20px;
  border-bottom: solid 1px #e8e8e8;
  overflow: auto;
}
.logo {
  width: 150px;
  float: left;
}
.logo a {
  display: inline-block;
  font-size: 20px;
  text-transform: capitalize;
}

.menuCon {
  width: calc(100% - 150px);
  float: left;
  display: flex;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}
.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}
.menuCon .ant-menu-horizontal {
  border-bottom: none;
}
.menuCon .leftMenu {
  margin-left: auto;
}
.menuCon .rightMenu {
  float: right;
  margin-left: auto;
}
.barsMenu {
  float: left;
  height: 32px;
  padding: 6px;
  margin-top: 14px;
  margin-left: 12px;
  display: none;
}
.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: #1890ff;
  position: relative;
}
.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
}
.barsBtn:after {
  top: auto;
  bottom: -6px;
}
.ant-drawer-body {
  padding: 0;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}
.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }
  .leftMenu,
  .rightMenu {
    display: none;
  }
  .logo a {
    margin-left: -20px;
  }
  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }
  .logo a {
    padding: 10px 20px;
  }
}

.bor-bt {
  border-bottom: 1.5px solid gainsboro;
}
