@media (max-width: 768px) {
  .sm-215 {
    width: unset !important;
    min-width: 215px !important;
  }

  .sm-pr-2 {
    padding-right: 0.5rem !important;
  }
  .sm-pl-2 {
    padding-left: 0.5rem !important;
  }

  .sm-pr-1 {
    padding-right: 0.25rem !important;
  }
  .sm-pl-1 {
    padding-left: 0.25rem !important;
  }

  .sm-ml-2 {
    margin-left: 0.5rem !important;
  }

  .sm-mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sm-mt-2 {
    margin-top: 0.5rem !important;
  }

  .sm-size-08 {
    font-size: 0.8rem !important;
  }
  .sm-size-06,
  .size-08.sm-size-06 {
    font-size: 0.6rem !important;
  }

  .sm-size-12,
  .size-15.sm-size-12 {
    font-size: 1.2rem !important;
  }

  .sm-size-10,
  .size-12.sm-size-10 {
    font-size: 1rem !important;
  }

  .sm-mt-0 {
    margin-top: 0px !important;
  }

  .sm-mb-0 {
    margin-bottom: 0px !important;
  }
}
