.ant-tabs-nav-scroll {
  display: flex;
}

.ant-tabs-nav {
  margin: auto;
  text-align: center;
}
.ant-tabs-bar {
  margin: 0px !important;
}
/* .ant-tabs-nav-container-scrolling{
 background-image: linear-gradient(to right, #f3b755, #e5366f, #e5366f, #f3b755) !important;
} */

.tab-bg .ant-tabs-nav-scroll {
  background-image: linear-gradient(
    to right,
    #f3b755,
    #e5366f,
    #f03975,
    #e5366f,
    #f3b755
  ) !important;
  color: #fff;
}

.tab-bg .tab-bg-w .ant-tabs-nav-scroll {
  background-image: unset !important;
}

.tab-bg-w .ant-tabs-tab:not(.ant-tabs-tab-active) {
  opacity: 0.6;
  color: #2c2e2c;
}

.ant-tabs-tab:not(.ant-tabs-tab-active) {
  opacity: 0.3;
  color: aliceblue;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #fff;
}

.ant-tabs-ink-bar {
  height: 4px !important;
  background-color: #f8d00f;
}
/* .ant-tabs {
  background-image: linear-gradient(to right, #f3b755, #e5366f, #e5366f, #f3b755) !important
} */
