.App {
  text-align: center;
}

@font-face {
  font-family: "Gebtam";
  src: url(./assets/fonts/paaymaay_regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sukhumvit";
  src: url(./assets/fonts/sukhumvit-light.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #f8d00f !important;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2.5px !important;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #f8d00f, 0 0 5px #f8d00f !important;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #f8d00f !important;
  border-left-color: #f8d00f !important;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bg-234 {
  background-color: rgb(234, 234, 234) !important;
}

.bg-gray {
  background-color: gainsboro !important;
}

.bg-advt {
  background-color: #eeeeee !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-page {
  display: flex;
  min-height: 100vh;
  background: rgb(240, 57, 117);
  background: -webkit-linear-gradient(
    left,
    rgba(211, 53, 106, 0.8),
    rgba(240, 57, 117, 1)
  );
  background: -o-linear-gradient(
    right,
    rgba(240, 57, 117, 0.8),
    rgba(240, 57, 117, 1)
  );
  background: -moz-linear-gradient(
    right,
    rgba(240, 57, 117, 0.8),
    rgba(240, 57, 117, 1)
  );
  background: linear-gradient(
    to right,
    rgba(240, 57, 117, 0.8),
    rgba(240, 57, 117, 1)
  );
  /* background-image: linear-gradient(to right, #f3b755, #e5366f,#f03975, #e5366f, #f3b755); */
}

.app-form {
  min-width: 250px;
  padding: 1rem !important;
  margin: auto !important;
  background: rgba(234, 234, 234, 1);
  background-color: transparent;
}
.login-form {
  min-width: 250px;
  padding: 1rem !important;
  margin: auto !important;
  background: rgba(234, 234, 234, 1);
  border-radius: 0.4rem;
  box-shadow: 2px 2px 5px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.regis-form {
  padding: 1rem !important;
  margin: auto !important;
  width: 400px;
  background: rgba(234, 234, 234, 1);
  border-radius: 0.4rem;
  box-shadow: 2px 2px 5px;
  max-width: 90%;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  font-size: 1rem;
}

.regis-form2 {
  padding: 1rem !important;
  margin: auto !important;
  width: 400px;
  background: rgba(234, 234, 234, 1);
  border-radius: 0.4rem;
  box-shadow: 2px 2px 5px;
  max-width: 90%;
  font-size: 1rem;
}

.profile-form {
  padding: 1rem !important;
  margin: auto !important;
  background: rgba(234, 234, 234, 0.5);
  border-radius: 0.4rem;
  /* box-shadow: 2px 2px 5px; */
  max-width: 90%;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  font-size: 1rem;
}
.size-06 {
  font-size: 0.6rem !important;
}
.size-07 {
  font-size: 0.7rem !important;
}
.size-08 {
  font-size: 0.8rem !important;
}
.size-09 {
  font-size: 0.9rem !important;
}
.size-10 {
  font-size: 1rem !important;
}
.size-11 {
  font-size: 1.1rem !important;
}
.size-12 {
  font-size: 1.2rem !important;
}
.size-15 {
  font-size: 1.5rem !important;
}
.size-16 {
  font-size: 1.6rem !important;
}
.size-17 {
  font-size: 1.7rem !important;
}
.size-18 {
  font-size: 1.8rem !important;
}
.size-19 {
  font-size: 1.9rem !important;
}
.size-20 {
  font-size: 2rem !important;
}
.size-21 {
  font-size: 2.1rem !important;
}
.size-22 {
  font-size: 2.2rem !important;
}

.mx-auto {
  margin: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-6 {
  padding-right: 2rem !important;
}
.mb-0 {
  margin-bottom: 0rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mt-4 {
  margin-top: 1.25rem !important;
}

.mb-4 {
  margin-bottom: 1.25rem !important;
}

.ml-4 {
  margin-left: 1.25rem !important;
}

.mr-4 {
  margin-right: 1.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}
.pl-1 {
  padding-left: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.logo-top {
  height: 50px;
  /* background: rgba(230, 46, 147, 0.2); */
  padding: 0;
  margin: 10px;
  text-align: center;
  align-items: center;
}

.radius {
  border-radius: 100%;
}

.radius-base {
  border-radius: 10px !important;
}

.radius-15 {
  border-radius: 1.5rem !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: rgb(224, 224, 224);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(214, 214, 214);
}

body {
  font-family: Sukhumvit;
}

.f-main,
.font-main {
  font-family: Gebtam !important;
}

.f-sub,
.font-sub {
  font-family: Sukhumvit !important;
}

.align-auto,
.align-center {
  align-items: center;
}

.c-main {
  color: #f03975 !important;
}

.c-gray {
  color: gray !important;
}

.opa-05 {
  opacity: 0.5 !important;
}
.c-suc {
  color: green !important;
}

.c-sub {
  color: #f8d00f !important;
}

.c-w {
  color: #fff !important;
}

.c-b {
  color: #2c2e2c !important;
}

.b-main,
.bg-main {
  background-color: #f03975 !important;
}

.b-sub,
.bg-sub {
  background-color: #f8d00f !important;
}

.b-w,
.bg-w {
  background-color: #fff !important;
}
.b-234 {
  background-color: rgb(234, 234, 234) !important;
}
.b-180 {
  background-color: rgb(180, 180, 180) !important;
}
.b-221 {
  background-color: #dddddd !important;
}

.pointer {
  cursor: pointer !important;
}

.disabled {
  cursor: no-drop;
  opacity: 0.8;
}

.w-100 {
  width: 100%;
}
.ab {
  position: absolute !important;
}

.rela {
  position: relative !important;
}

.bt {
  bottom: 0px;
}

.t-0 {
  top: 0px;
}

.top-unset {
  top: unset !important;
}

.b-0 {
  bottom: 0px;
}

.hide {
  display: none;
}

.pd-0 {
  padding: 0rem !important;
}
.pd-1 {
  padding: 0.25rem !important;
}
.pd-2 {
  padding: 0.5rem !important;
}

.pd-3 {
  padding: 1rem !important;
}

.pd-1 {
  padding: 0.25rem !important;
}

.mg-0 {
  margin: 0px !important;
}
.mg-1 {
  margin: 0.25rem !important;
}

.mg-2 {
  margin: 0.5rem !important;
}

.mg-3 {
  margin: 1rem !important;
}

.border {
  border: 0.5px solid #e8e8e8;
}

.no-border {
  border: 0px !important;
}

.h-100 {
  height: 100%;
}

.d-flex {
  display: flex !important;
}
.fit-content {
  width: fit-content;
}

.center {
  margin: auto !important;
  text-align: center;
}

.max-90 {
  max-height: 84vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.x-auto,
.ReactVirtualized__Grid__innerScrollContainer {
  overflow-x: auto !important;
  overflow-y: hidden;
}

.w-fit {
  min-width: fit-content !important;
}
.bold {
  font-weight: bold !important;
}

.l-0 {
  left: 0;
}

.r-0 {
  right: 0;
}

.t-0 {
  top: 0;
}

.b-0 {
  bottom: 0;
}

.pb-6 {
  padding-bottom: 2rem !important;
}

.default {
  cursor: default !important;
}

.h-3 {
  height: 3rem !important;
  line-height: 3rem !important;
}

.h-5 {
  height: 5rem !important;
  line-height: 5rem !important;
}

.h-2 {
  height: 2rem !important;
  line-height: 2rem !important;
}

.radius-0 {
  border-radius: 0 !important;
}

.weight-400 {
  font-weight: 400 !important;
}

.weight-200 {
  font-weight: 200 !important;
}
.input-h-5 input {
  height: 5rem !important;
  font-size: 5rem;
  line-height: 5rem;
  text-align: center;
}

.size-50 {
  font-size: 5rem !important;
}
.size-small {
  font-size: small !important;
}

.raduis-1 {
  border-radius: 1rem !important;
}

.shadow {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.line-10,
.line-h-10 {
  line-height: 1rem !important;
}
.line-11,
.line-h-11 {
  line-height: 1.1rem !important;
}

.line-12,
.line-h-12 {
  line-height: 1.2rem !important;
}

.line-15,
.line-h-15 {
  line-height: 1.5rem !important;
}

.b-grad {
  background-image: linear-gradient(
    to right,
    #f3b755,
    #e4336e,
    #f03975,
    #e5366f,
    #f3b755
  ) !important;
}

.b-216 {
  background-color: rgb(216, 216, 216);
}

.ReactVirtualized__Grid:focus,
.ReactVirtualized__Grid__innerScrollContainer:focus {
  outline: unset !important;
}

.under-hover:hover {
  text-decoration: underline;
}

.h-100 {
  height: 100% !important;
}

.flex-100 {
  flex: 0 0 100px;
}

.flex-130 {
  flex: 0 0 130px;
}

.flex-150 {
  flex: 0 0 150px;
}

.flex-200 {
  flex: 0 0 200px;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.over-hide {
  overflow: hidden;
}

.custom-link a {
  word-break: break-all;
}

.border-bt-gray {
  border-bottom: 1px solid gainsboro;
}

.no-resize {
  resize: unset !important;
}

.border-medium {
  border: 1.5px solid gainsboro;
}

.break-all {
  word-break: break-all !important;
}

.copied-code {
  padding: 2px 4px;
  font-size: 12px;
  background: #f5f5f5;
  border-radius: 2px;
  font-weight: 300 !important;
}
.cursor-default {
  cursor: default !important;
}
.login-page .ant-tabs .ant-tabs-top-content {
  max-width: 218px !important;
}

.login-page .ant-tabs .ant-tabs-bar {
  display: none !important;
}

.login-page .ant-tabs .ant-tabs-top-content.ant-tabs-content-animated,
.login-page .ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated {
  -webkit-transition: margin-left 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition: margin-left 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
