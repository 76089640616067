.feed-comment-top .ant-comment-content-detail {
  line-height: 1rem;
}

.feed-comment-top .ant-comment-avatar img {
  width: 35px;
  height: 35px;
}

.feed-comment-top .ant-comment-avatar {
  margin-right: 6px;
}

.feed-comment-top .ant-comment-inner {
  padding: 4px 0;
}

.custom-comment .ant-comment-inner {
  padding: 0px;
}

.custom-comment .ant-comment-actions {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
