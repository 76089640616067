.raduis-1 .ant-input-number-handler-wrap {
  border-radius: 1rem;
}

.ant-modal-confirm-btns {
  font-family: Gebtam;
}
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #f03975 !important;
}

.ant-modal-confirm-btns button {
  font-size: 1.5rem;
  line-height: 2rem;
}

.ant-modal-confirm-title {
  font-size: 1.5rem !important;
}

.ant-modal-confirm-body svg {
  margin-top: 0.3rem;
}

.card .ant-card-body {
  padding: 0rem;
}

.ant-layout.ant-layout-has-sider > .ant-layout {
  overflow-y: hidden;
  overflow-x: hidden;
}

.ant-message-notice span {
  font-family: Sukhumvit !important;
  font-size: 1rem;
  font-weight: bold;
}

.promotion-meta .ant-card-meta-description {
  min-height: 2.6rem !important;
}

.ant-layout-sider .ant-menu-item .anticon {
  vertical-align: 1px;
}
.ant-layout-sider .ant-menu-dark .ant-menu-submenu-selected {
  background-color: #f8d00f !important;
}

.ant-layout-sider .anticon .not-active img {
  filter: brightness(0) invert(1);
}

.ant-progress-line:not(.ant-progress-status-success) .ant-progress-inner {
  border: 1px solid #f03975;
}

.ant-message-info .anticon {
  color: #f03975 !important;
}

.ant-page-header {
  padding: 8px 16px !important;
}

.ant-page-header-content {
  padding-top: 0px !important;
}

.ant-drawer-wrapper-body {
  background-color: #f03975;
  font-family: "Gebtam";
}
.ant-drawer-wrapper-body .anticon .not-active img {
  filter: brightness(0) invert(1);
}

.round,
.round .ant-select-selection {
  border-radius: 32px !important;
}

.flex-grow-1 .ant-form-item-control-wrapper,
.flex-grow-1 .ant-form-item-control {
  min-height: calc(100% - 11px);
  height: calc(100% - 11px);
}

.custom-description .ant-descriptions-title {
  margin-bottom: 5px !important;
}

.custom-description .ant-descriptions-row > td {
  padding-bottom: 0px !important;
}

.order-meta .ant-card-meta-title {
  white-space: unset;
}

.customtime-width-200 .ant-time-picker-panel-input-wrap {
  width: 200px;
  max-width: unset !important;
}

.customtime-width-200 .ant-time-picker-panel-select,
.customtime-width-200 .ant-time-picker-panel-select {
  width: 50%;
}

.customtime-width-200 .ant-time-picker-panel-select ul {
  width: 100%;
}

.regis-form .ant-page-header-back-button {
  color: #f03975 !important;
}

.rela .ant-affix {
  position: sticky !important;
}
